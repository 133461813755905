import S3 from "react-aws-s3";
import { S3Config } from "../config";
import { v4 as uuidv4 } from "uuid";
import http from "../http-common";
import axios from "axios";

export async function _olduploadFilesToS3(file, folderId) {
  return new Promise((resolve, reject) => {
    const ext = file.name.split(".").pop();
    const fileName = generateUniqueFileName(ext);
    let newFileName = `${folderId}/` + fileName;
    const ReactS3Client = new S3(S3Config);
    ReactS3Client.uploadFile(file, newFileName)
      .then((data) => {
        if (data.status === 204) {
          resolve(data);
        } else {
          reject(data);
        }
      })
      .catch((err) => {
        console.log(err);
        reject(err);
      });
  });
}

export async function uploadFilesToS3(file, folderId) {
  try {
    const ext = file.name.split(".").pop();
    const fileName = generateUniqueFileName(ext);
    const fileKey = `${folderId}/${fileName}`;

    // Step 1: Get the signed URL
    const { data } = await http.post("/generate/sign-url", {
      fileKey,
      expiration: 3600,
    });

    const { url } = data;

    // Step 2: Upload file to S3 using the signed URL
    const blob = new Blob([file], { type: file.type });
    await axios.put(url, blob, {
      headers: {
        "Content-Type": "application/octet-stream",
      },
    });
    const fileUrl = url.split("?")[0];
    return { bucket: "be-music", key: fileKey, location: fileUrl, status: 204 };
  } catch (error) {
    console.error("Error uploading file:", error);
    throw error;
  }
}

const generateUniqueFileName = (ext) => {
  return uuidv4() + "." + ext;
};
