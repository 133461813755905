import React, { useState } from "react";
import {
  Typography,
  TextField,
  Input,
  Grid,
  InputLabel,
  Button,
} from "@material-ui/core";
import Switch from "@material-ui/core/Switch";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Form, Formik } from "formik";
import SelectComponent from "../components/SelectComponent";
import * as Yup from "yup";
import Alert from "@material-ui/lab/Alert";
import MainLayout from "../Layout/MainLayout";
import { useSnackbar } from "notistack";
import { uploadFilesToS3 } from "../services/s3FileUplad";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { v4 as uuidv4 } from "uuid";
import uploadFilesService from "../services/upload-files.service";
import CancelIcon from "@material-ui/icons/Cancel";

const useStyles = makeStyles((theme) =>
  createStyles({
    input: {
      // padding: "0px 0px 15px 0px",
      marginBottom: "1rem",
      width: "80%",
    },
    error: {
      color: "red",
      marginBottom: ".5rem",
    },
    uploadWarning: {
      fontWeight: "bold",
      fontSize: "20px",
      color: "#ea5252",
    },
    uploadActive: {
      fontWeight: "bold",
      fontSize: "20px",
      color: "#41c300",
    },
    uploadDisabled: {
      fontWeight: "normal",
      fontSize: "16px",
      color: "#eeeeee",
    },
  }),
);

const SUPPORTED_IMAGE = ["jpg", "jpeg", "gif", "png"];
const SUPPORTED_LYRICS = ["txt"];
const SUPPORTED_MUSIC = ["mp3"];
const ext = /.(\w+)$/;
const FormSchema = Yup.object().shape({
  title: Yup.string().required("Title is Required"),
  artist: Yup.string().required("Artist is Required"),
  tags: Yup.string().required("Tag is Required"),
  category: Yup.string().required("Category is Required"),
  music: Yup.mixed().required(
    `Supported (${SUPPORTED_MUSIC.join(", ")}) Music file is Required`,
  ),
  image: Yup.mixed()
    .required()
    .test("fileFormat", "Unsupported Image Format", function (value) {
      if (value) {
        return SUPPORTED_IMAGE.includes(ext.exec(value.key)[1]);
      }
      return false;
    }),
  lyrics: Yup.mixed()
    .required()
    .test("fileFormat", "Unsupported File Format", function (value) {
      if (value) {
        return value && SUPPORTED_LYRICS.includes(ext.exec(value.key)[1]);
      }
      return false;
    }),
});

function Uploader(props) {
  const classes = useStyles();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const msg = localStorage.getItem("message");
  const [message, setMessage] = useState(msg);
  const [uploadKaraoke, setUploadKaraoke] = React.useState(true);
  const [folderId, setFolderId] = React.useState(null);
  const [musicLoading, setMusicLoading] = useState(null);
  const [imageLoading, setImageLoading] = useState(null);
  const [lyricsLoading, setLyricsLoading] = useState(null);

  const handleSwitchChange = (event) => {
    setUploadKaraoke(event.target.checked);
  };

  const handleMessageClose = () => {
    setMessage("");
    localStorage.removeItem("message");
  };
  const setLoadingState = (context, value) => {
    switch (context) {
      case "music":
        setMusicLoading(value);
        break;
      case "image":
        setImageLoading(value);
        break;
      case "lyrics":
        setLyricsLoading(value);
        break;
      default:
        break;
    }
  };

  const validateFileType = (context, value) => {
    switch (context) {
      case "music":
        return SUPPORTED_MUSIC.includes(value);
      case "image":
        return SUPPORTED_IMAGE.includes(value);
      case "lyrics":
        return SUPPORTED_LYRICS.includes(value);
      default:
        return false;
    }
  };

  const handleFileUpload = async (event, setFieldValue, field, errors) => {
    const file = event.target.files[0];
    const extension = file?.name?.split(".")?.pop();

    if (validateFileType(field, extension)) {
      setLoadingState(field, true);
      uploadFilesToS3(event.target.files[0], folderId)
        .then((res) => {
          setFieldValue(field, res, true);
          setLoadingState(field, false);
        })
        .catch((err) => {
          setLoadingState(field, "error");
        });
    } else {
      setLoadingState(field, "error");
    }
  };

  React.useEffect(() => {
    setFolderId(uuidv4());
    localStorage.removeItem("message");
  }, []);
  return (
    <MainLayout>
      <div className="container">
        <div className="mg20">
          <Typography variant="h5">bMusicTime Uploader</Typography>
        </div>
        <Formik
          initialValues={{
            title: "",
            artist: "",
            tags: "",
            category: "",
            music: null,
            image: null,
            lyrics: null,
            movie: null,
            album: null,
            music_director: null,
            producer: null,
          }}
          validationSchema={FormSchema}
          validateOnMount
          onSubmit={(values, { setSubmitting, resetForm }) => {
            // same shape as initial values
            setTimeout(() => {
              const formData = {};
              formData.file = values.music;
              formData.image = values.image;
              formData.lyrics = values.lyrics;
              formData.title = values.title.trim();
              formData.tags = values.tags.trim();
              formData.artist = values.artist.trim();
              formData.id = folderId;
              formData.category = values.category.trim();
              //Optional value
              if (values.movie) formData.movie = values.movie.trim();
              if (values.album) formData.album = values.album.trim();
              if (values.music_director)
                formData.musicDirector = values.music_director.trim();
              if (values.producer) formData.producer = values.producer.trim();
              if (uploadKaraoke) formData.converter = "true";

              uploadFilesService.submitFormData(
                formData,
                setSubmitting,
                enqueueSnackbar,
              );
            }, 400);
          }}
        >
          {({
            errors,
            touched,
            handleChange,
            isValid,
            isSubmitting,
            setFieldValue,
            setFieldTouched,
            values,
          }) => (
            <Form>
              <Grid container>
                <Grid item xs={12}>
                  {/* <UploadFiles
                  setFile={setMusic}
                  name="music"
                /> */}
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <p
                      className={`${
                        uploadKaraoke ? classes.disabled : classes.uploadActive
                      }`}
                    >
                      {uploadKaraoke ? (
                        <s>Karaoke Track</s>
                      ) : (
                        <p>Karaoke Track</p>
                      )}
                    </p>
                    <Switch
                      checked={uploadKaraoke}
                      onChange={handleSwitchChange}
                      color="primary"
                      name="checkedB"
                      inputProps={{ "aria-label": "Original song?" }}
                    />
                    <p
                      className={`${
                        !uploadKaraoke ? classes.disabled : classes.uploadActive
                      }`}
                    >
                      {!uploadKaraoke ? (
                        <s>Orignal Song</s>
                      ) : (
                        <p>Orignal Song</p>
                      )}
                    </p>
                  </div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    Upload Music{" "}
                    {musicLoading === true && (
                      <CircularProgress
                        style={{ marginLeft: "10px" }}
                        size={20}
                      />
                    )}
                    {musicLoading === false && (
                      <CheckCircleIcon
                        style={{ marginLeft: "10px", color: "green" }}
                      />
                    )}
                    {musicLoading === "error" && (
                      <CancelIcon
                        style={{ marginLeft: "10px", color: "red" }}
                      />
                    )}
                  </div>
                  <Input
                    name="music"
                    onChange={(e) =>
                      handleFileUpload(e, setFieldValue, "music", isValid)
                    }
                    type="file"
                    className={classes.input}
                    onBlur={() => setFieldTouched("music")}
                  />

                  {errors.music && touched.music ? (
                    <div className={classes.error}>{errors.music}</div>
                  ) : null}
                </Grid>

                <Grid item xs={12}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    Upload Image <i>(Must be .jpg/jpeg/png file extension)</i>
                    {imageLoading === true && (
                      <CircularProgress
                        style={{ marginLeft: "10px" }}
                        size={20}
                      />
                    )}
                    {imageLoading === false && (
                      <CheckCircleIcon
                        style={{ marginLeft: "10px", color: "green" }}
                      />
                    )}
                    {imageLoading === "error" && (
                      <CancelIcon
                        style={{ marginLeft: "10px", color: "red" }}
                      />
                    )}
                  </div>
                  <Input
                    name="image"
                    onChange={(e) =>
                      handleFileUpload(e, setFieldValue, "image")
                    }
                    type="file"
                    className={classes.input}
                    inputProps={{ accept: "image/*" }}
                    onBlur={() => setFieldTouched("image")}
                  />
                  {errors.image && touched.image ? (
                    <div className={classes.error}>{errors.image}</div>
                  ) : null}
                </Grid>

                <Grid item xs={12}>
                  <div>
                    Upload Lyrics <i>(Must be .txt file extension)</i>
                    {lyricsLoading === true && (
                      <CircularProgress
                        style={{ marginLeft: "10px" }}
                        size={20}
                      />
                    )}
                    {lyricsLoading === false && (
                      <CheckCircleIcon
                        style={{ marginLeft: "10px", color: "green" }}
                      />
                    )}
                    {lyricsLoading === "error" && (
                      <CancelIcon
                        style={{ marginLeft: "10px", color: "red" }}
                      />
                    )}
                  </div>
                  <Input
                    name="lyrics"
                    onChange={(e) =>
                      handleFileUpload(e, setFieldValue, "lyrics")
                    }
                    type="file"
                    inputProps={{ accept: "text/plain" }}
                    className={classes.input}
                    onBlur={() => setFieldTouched("lyrics")}
                  />

                  {errors.lyrics && touched.lyrics ? (
                    <div className={classes.error}>{errors.lyrics}</div>
                  ) : null}
                </Grid>

                <Grid item xs={12}>
                  <InputLabel htmlFor="title">Title</InputLabel>
                  <TextField
                    className={classes.input}
                    id="title"
                    name="title"
                    onChange={(e) => {
                      setFieldValue("title", e.target.value);
                    }}
                    onBlur={() => setFieldTouched("title")}
                  />
                  {errors.title && touched.title ? (
                    <div className={classes.error}>{errors.title}</div>
                  ) : null}
                </Grid>
                <Grid item xs={12}>
                  <InputLabel htmlFor="artist">Artist</InputLabel>
                  <TextField
                    className={classes.input}
                    name="artist"
                    id="artist"
                    onChange={(e) => {
                      setFieldValue("artist", e.target.value);
                    }}
                    onBlur={() => setFieldTouched("artist")}
                  />
                  {errors.artist && touched.artist ? (
                    <div className={classes.error}>{errors.artist}</div>
                  ) : null}
                </Grid>
                <Grid item xs={12}>
                  <InputLabel htmlFor="movie">Movie (optional)</InputLabel>
                  <TextField
                    className={classes.input}
                    name="movie"
                    id="movie"
                    onChange={(e) => {
                      setFieldValue("movie", e.target.value);
                    }}
                    onBlur={() => setFieldTouched("movie")}
                  />
                  {errors.movie && touched.movie ? (
                    <div className={classes.error}>{errors.movie}</div>
                  ) : null}
                </Grid>
                <Grid item xs={12}>
                  <InputLabel htmlFor="album">Album (optional)</InputLabel>
                  <TextField
                    className={classes.input}
                    name="album"
                    id="album"
                    onChange={(e) => {
                      setFieldValue("album", e.target.value);
                    }}
                    onBlur={() => setFieldTouched("album")}
                  />
                  {errors.album && touched.album ? (
                    <div className={classes.error}>{errors.album}</div>
                  ) : null}
                </Grid>
                <Grid item xs={12}>
                  <InputLabel htmlFor="music_director">
                    Music Director (optional)
                  </InputLabel>
                  <TextField
                    className={classes.input}
                    name="music_director"
                    id="music_director"
                    onChange={(e) => {
                      setFieldValue("music_director", e.target.value);
                    }}
                    onBlur={() => setFieldTouched("music_director")}
                  />
                  {errors.music_director && touched.movie ? (
                    <div className={classes.error}>{errors.music_director}</div>
                  ) : null}
                </Grid>
                <Grid item xs={12}>
                  <InputLabel htmlFor="producer">
                    Producer (optional)
                  </InputLabel>
                  <TextField
                    className={classes.input}
                    name="producer"
                    id="producer"
                    onChange={(e) => {
                      setFieldValue("producer", e.target.value);
                    }}
                    onBlur={() => setFieldTouched("producer")}
                  />
                  {errors.producer && touched.movie ? (
                    <div className={classes.error}>{errors.producer}</div>
                  ) : null}
                </Grid>
                <Grid item xs={12}>
                  {/* <InputLabel htmlFor="tags">Tags</InputLabel> */}
                  {/* <TextField
                  className={classes.input}
                  name="tags"
                  id="tags"
                  onChange={handleChange}
                /> */}
                  <SelectComponent
                    name="tags"
                    label="Tags"
                    options={["premium", "new", "recomended"]}
                    setFieldValue={setFieldValue}
                    setFieldTouched={setFieldTouched}
                    multiple={true}
                  ></SelectComponent>
                  {errors.tags && touched.tags ? (
                    <div className={classes.error}>{errors.tags}</div>
                  ) : null}
                </Grid>
                <Grid item xs={12}>
                  <SelectComponent
                    name="category"
                    label="Category"
                    options={["english", "bangla", "hindi", "sounds"]}
                    setFieldValue={setFieldValue}
                    setFieldTouched={setFieldTouched}
                    multiple={true}
                  ></SelectComponent>
                  {errors.category && touched.category ? (
                    <div className={classes.error}>{errors.category}</div>
                  ) : null}
                </Grid>
                <Button
                  style={{ marginTop: "1rem", marginBottom: "10rem" }}
                  disabled={!isValid || isSubmitting}
                  variant="contained"
                  color="primary"
                  type="submit"
                >
                  Submit
                  {isSubmitting && (
                    <CircularProgress
                      style={{
                        width: "25px",
                        height: "25px",
                        marginLeft: "10px",
                      }}
                      color="secondary"
                    />
                  )}
                </Button>
              </Grid>
            </Form>
          )}
        </Formik>
        {message && (
          <Alert severity="success">
            {message}{" "}
            <span
              style={{ textDecoration: "none" }}
              onClick={handleMessageClose}
            >
              close
            </span>
          </Alert>
        )}
      </div>
    </MainLayout>
  );
}

export default Uploader;
